import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'components/general/Link';
import Slider from 'components/general/Slider';

import { cdnURL } from '@inspire/util/cdn';

const partners = [
  {
    _id: 'mGeKWnNneyj5uwQ8W',
    name: 'CGET',
    url: 'http://www.cget.gouv.fr/',
    imageUrl: cdnURL('/878-cget1.png'),
  },
  {
    _id: 'mukbbCMCBTYN625eE',
    name: 'FEDER',
    url: 'http://www.europeidf.fr/action-europeenne/programmes-action/feder-fse-iej',
    imageUrl: cdnURL('/6044-logos_feder2.png'),
  },
  {
    _id: 'ZmbAAbtvmEMnmLWxM',
    name: 'Uniscité',
    url: 'http://www.uniscite.fr/',
    imageUrl: cdnURL('/2462-logo_uniscite.jpg'),
  },
  {
    _id: 'LAsgk6uJhuDtsFKwt',
    name: 'FAGE',
    url: 'https://www.fage.org/',
    imageUrl: cdnURL('/7003-fage1.png'),
  },
  {
    _id: 'kYTLN3cKMkqKun5nu',
    name: 'Fondation HEC',
    url: 'http://www.fondationhec.fr/',
    imageUrl: cdnURL('/2431-fondationhec.png'),
  },
  {
    _id: 'as2ajkACDKv6fRgXm',
    name: 'O21 Le Monde',
    url: 'http://www.lemonde.fr/o21/',
    imageUrl: cdnURL('/5991-lemonde.jpeg'),
  },
  {
    _id: 'GSwdAcPwMCavb3X5M',
    name: 'ONISEP',
    url: 'http://www.onisep.fr/',
    imageUrl: cdnURL('/6093-onisep.jpg'),
  },
  {
    _id: 'aWf9Lm5Dpxgm22NRq',
    name: 'MOEL',
    url: 'http://www.monorientationenligne.fr/inspire',
    imageUrl: cdnURL('/2258-moel.png'),
  },
  {
    _id: 'Z42eCxopEz7aPZq9S',
    name: 'Animafac',
    url: 'https://www.animafac.net/',
    imageUrl: cdnURL('/6750-animacfac.jpg'),
  },
  {
    _id: 'KSD5p7Eevn8pr7X2Y',
    name: 'Cordées de la réussite',
    url: 'http://www.cordeesdelareussite.fr/',
    imageUrl: cdnURL('/7503-logo_cordedelareussite_2021.png'),
  },
  {
    _id: 'adw7fW9fDX8hWtt3i',
    name: 'Centre Egalité des Chances ESSEC',
    url: 'http://egalite-des-chances.essec.edu/',
    imageUrl: cdnURL('/5199-essec.png'),
  },
  {
    _id: 'RuAss9Q99NnECsRTa',
    name: 'AFEV',
    url: 'http://afev.org/',
    imageUrl: cdnURL('/8344-afev_logo_hd.jpg'),
  },
  {
    _id: 'ban34ojvQBGBzeCau',
    name: 'Ministère de lʼEnseignement supérieur, de la Recherche et de lʼInnovation',
    url: 'http://www.enseignementsup-recherche.gouv.fr/',
    imageUrl: cdnURL('/1850-mesri_2020_header_1343552.png'),
  },
  {
    _id: 'Y6L3B8uoEs9RtPuNW',
    name: 'FUN MOOC',
    url: 'https://www.fun-mooc.fr/',
    imageUrl: cdnURL('/1626-fun_mooc.png'),
  },
  {
    _id: 'Qq2bHYfpW5ZJfwziK',
    name: 'Région Hauts-de-France',
    imageUrl: cdnURL('/9274-hdf.jpg'),
  },
  {
    _id: '5T8TwGqjKdDYhpFdn',
    name: 'Je Veux Aider',
    imageUrl: cdnURL('/3712-logo_jva_gouv_carre_light.png'),
    url: 'https://www.jeveuxaider.gouv.fr/',
  },
];

const PartnersSlider = ({ isMobile = false }) => {
  const settings = {
    autoPlay: true,
    autoPlaySpeed: 1000,
    infinite: true,
    slidesToShow: 4,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 4 } },
      { breakpoint: 900, settings: { slidesToShow: 3 } },
      { breakpoint: 650, settings: { slidesToShow: 1 } },
    ],
  };
  return (
    <Slider settings={settings} isMobile={isMobile}>
      {partners.map((p, i) => {
        return (
          <Box key={i}>
            {p.url ? (
              <Link key={i} href={p.url} target="_blank">
                <Box
                  maxW="90%"
                  m={['10px', '20px', '30px']}
                  height={isMobile ? '200px' : '80px'}
                  pos="relative"
                >
                  <Image
                    src={p.imageUrl}
                    alt={p.name}
                    layout="fill"
                    objectFit="contain"
                  />
                </Box>
              </Link>
            ) : (
              <Box
                maxW="90%"
                m={['10px', '20px', '30px']}
                height="80px"
                pos="relative"
              >
                <Image
                  src={p.imageUrl}
                  alt={p.name}
                  layout="fill"
                  objectFit="contain"
                />
              </Box>
            )}
          </Box>
        );
      })}
    </Slider>
  );
};

export default PartnersSlider;
