import Slider from 'react-slick';
import { Box, IconButton } from '@chakra-ui/react';
import { ChevronRight, ChevronLeft } from '@inspire/ui/icons';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SlickArrow = (props) => {
  const { icon, style, onClick, extraStyle, className } = props;
  return (
    <IconButton
      color={className.includes('slick-disabled') ? 'gray.300' : 'black'}
      aria-label="next"
      icon={icon}
      variant="ghost"
      onClick={onClick}
      borderRadius={99}
      pos="absolute"
      top="50%"
      transform="translateY(-50%)"
      _hover={{ bg: 'transparent' }}
      _active={{ bg: 'transparent' }}
      _focus={{ bg: 'transparent' }}
      style={{
        ...style,
        display: 'flex',
        ...extraStyle,
      }}
    />
  );
};

const slickSettings = (isMobile = false) => {
  return {
    slidesToShow: 3,
    infinite: false,
    swipeToSlide: true,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3 } },
      { breakpoint: 900, settings: { slidesToShow: 2 } },
      { breakpoint: 650, settings: { slidesToShow: 1 } },
    ],
    nextArrow: (
      <SlickArrow
        icon={<ChevronRight size={isMobile ? '5rem' : '2rem'} />}
        extraStyle={{ right: '-14px' }}
      />
    ),
    prevArrow: (
      <SlickArrow
        icon={<ChevronLeft size={isMobile ? '5rem' : '2rem'} />}
        extraStyle={{ left: '-14px' }}
      />
    ),
    customPaging: function () {
      return isMobile ? (
        <Box
          bg="grey"
          borderRadius="50%"
          height="60%"
          width="60%"
          marginTop="30px"
        />
      ) : (
        <Box bg="grey" borderRadius="50%" height="20%" width="20%" />
      );
    },
  };
};

const InspireSlider = ({
  settings = {},
  style = {},
  children,
  isMobile = false,
}) => (
  <Slider
    {...slickSettings(isMobile)}
    {...settings}
    style={{ paddingLeft: '20px', paddingRight: '20px', ...style }}
    isMobile
  >
    {children}
  </Slider>
);

export default InspireSlider;
