import {
  Box,
  Center,
  Container,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import Lottie from 'lottie-react';

import {
  inspireYellow,
  lightBeigeBg,
  lightBlueBg,
  lightYellowBg,
  sansFonts,
} from '@inspire/ui/static';
import Row from '@inspire/ui/chakra/layout/Row';
import { RightArrowIcon } from '@inspire/ui/icons';
import Layout from 'components/layout/Layout';
import TestimonialsSlider from 'components/landing-pages/TestimonialsSlider';
import SocialChannels from 'components/landing-pages/SocialChannels';
import NoWrap from 'components/landing-pages/NoWrap';

import inspireAnimation from '../../public/img/animations/animation-inspire.lottie.json';
import LinkButton from 'components/general/LinkButton';
import {
  INSCRIPTION_PATH,
  KIT_DE_SURVIE_PATH,
  LANDING_PAGE_EQUIPE_PEDAGOGIQUE,
  LANDING_PAGE_ETUDIANT,
  LANDING_PAGE_LYCEEN,
} from 'lib/paths';
import Span from '@inspire/ui/chakra/Span';
import {
  emphasisButtonProps,
  roundButtonProps,
} from '@inspire/ui/chakra/theme';
import { DemoLogin, isDemo } from 'components/auth/DemoLogin';
import {
  GTM_ACTION_LANDING_PAGE_PRINCIPALE,
  GTM_CATEGORY_LANDING_PAGES,
  GTM_ACTION_LANDING_PAGE_PROF,
  GTM_ACTION_LANDING_PAGE_LYCEEN,
  GTM_ACTION_LANDING_PAGE_STUDENT,
  GTM_ACTION_CLICK_TEXT,
  pushEvent,
} from 'lib/gtm';
import { OnlyLoggedOut } from 'components/auth/Redirects';
import PartnersSlider from 'components/general/PartnersSlider';

export const testimonials = [
  {
    _id: 1,
    imageUrl: '/img/landing-pages/temoignages/brun-orange.jpg',
    description:
      'C’est super de découvrir une nouvelle plateforme et de nouvelles façons de s’informer sur notre orientation surtout quand on est totalement perdu face à ça et que c’est difficile de se motiver à chercher. Donc avoir une plateforme claire c’est bien.',
  },
  {
    _id: 2,
    imageUrl: '/img/landing-pages/temoignages/brune.jpg',
    description:
      "Les licences et choix post bac qui m'ont été proposés étaient pertinents, ça m'aide à me situer sur ce que je dois viser et sur ce que chaque licence engage.",
  },
  {
    _id: 3,
    imageUrl: '/img/landing-pages/temoignages/blonde.jpg',
    description:
      'Les étudiantes étaient très sympathiques et savaient répondre à tous types de questions. J’ai appris énormément de choses et elles m’ont rassuré. Merci infiniment !',
  },
  {
    _id: 4,
    imageUrl: '/img/landing-pages/temoignages/frisee-orange.jpg',
    description:
      'J’ai appris de nombreuses choses qui vont pouvoir me permettre de me débrouiller lorsque j’entrerai dans ma vie d’étudiante.',
  },
  {
    _id: 5,
    imageUrl: '/img/landing-pages/temoignages/sandwich.jpg',
    description:
      "J'ai découvert différentes possibilités qui pourraient me permettre d'atteindre mes objectifs même sans passer directement par la licence. Je peux choisir le meilleur chemin pour moi.",
  },
];

const IndexPage = () => (
  <Box bg="white" overflow="hidden">
    {/* Header compensation */}
    <Row bgColor={lightBeigeBg} pt={['56px', '64px']}>
      <Container maxW="container.lg" py={12}>
        <Stack
          direction={['column', null, 'row']}
          align="center"
          spacing={[3, null, 10]}
          px={[3, null, 0]}
        >
          <Box>
            <Heading
              as="h2"
              mt={0}
              size="2xl"
              textAlign={['center', null, 'left']}
            >
              Cherche ta voie avec ceux qui l’ont trouvée
            </Heading>
            <Box>
              <Heading
                as="h3"
                size="md"
                fontSize="3xl"
                textAlign={['center', null, 'left']}
              >
                <Box
                  borderBottom="1px solid black"
                  fontWeight="500"
                  display={['none', null, 'inline-block']}
                >
                  INSPIRE
                </Box>
              </Heading>
              <Text fontSize={['lg', null, 'xl']}>
                <Span fontWeight="bold" display={['inline', null, 'none']}>
                  INSPIRE –{' '}
                </Span>
                Une communauté et un accompagnement entièrement gratuits pour
                t'aider dans ton orientation.
              </Text>
            </Box>
          </Box>
          <Spacer />
          <Box
            maxW="360px"
            lineHeight={0}
            borderRadius="20px"
            overflow="hidden"
            shadow="xl"
            // https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
            // @ts-ignore
            isolation="isolate"
          >
            <Lottie animationData={inspireAnimation} loop={true} />
          </Box>
        </Stack>
        {isDemo && (
          <Box mt={12}>
            <DemoLogin />
          </Box>
        )}
      </Container>
    </Row>

    {/* User types */}
    <Row bgColor={inspireYellow} py={12}>
      <Container maxW="container.lg">
        <SimpleGrid columns={[1, null, 3]} spacing={8}>
          <Box>
            <Box textAlign="center" lineHeight={0} mb={7}>
              <Image
                h="200px"
                src="/img/landing-pages/lyceen.png"
                alt="Lycéen"
              />
            </Box>
            <Heading as="h4" size="md" my={6} textAlign="center">
              Lycéen
            </Heading>
            <Text mb={0}>
              On t’explique le fonctionnement des études supérieures, comment
              remplir ton dossier Parcoursup et surtout, on te permet de
              contacter des étudiants pour leur poser des questions.
            </Text>
            <Box
              textAlign="center"
              fontWeight="bold"
              fontSize="lg"
              mt={5}
              mb={5}
            >
              <LinkButton
                variant="outline"
                colorScheme="black"
                {...roundButtonProps}
                href={LANDING_PAGE_LYCEEN}
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_LANDING_PAGES,
                    GTM_ACTION_LANDING_PAGE_LYCEEN,
                    'En savoir plus',
                    undefined,
                    'CTA LP'
                  );
                }}
              >
                En savoir plus
              </LinkButton>
            </Box>
          </Box>
          <Box>
            <Box textAlign="center" lineHeight={0} mb={7}>
              <Image
                h="200px"
                src="/img/landing-pages/main-prof.svg"
                alt="Équipe pédagogique"
              />
            </Box>
            <Heading as="h4" size="md" my={6} textAlign="center">
              Équipe pédagogique
            </Heading>
            <Text mb={0}>
              Reçois un éclairage complémentaire aux informations données par
              ton entourage. L’équipe pédagogique de ton établissement peut
              aussi s'inscrire et t'accompagner sur INSPIRE.
            </Text>
            <Box
              textAlign="center"
              fontWeight="bold"
              fontSize="lg"
              mt={5}
              mb={5}
            >
              <LinkButton
                variant="outline"
                colorScheme="black"
                {...roundButtonProps}
                href={LANDING_PAGE_EQUIPE_PEDAGOGIQUE}
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_LANDING_PAGES,
                    GTM_ACTION_LANDING_PAGE_PROF,
                    'En savoir plus',
                    undefined,
                    'CTA LP'
                  );
                }}
              >
                En savoir plus
              </LinkButton>
            </Box>
          </Box>
          <Box>
            <Box textAlign="center" lineHeight={0} mb={7} h="200px">
              <Image
                h="240px"
                mt={-10}
                ml={20}
                src="/img/landing-pages/etudiant.svg"
                alt="Étudiant Éclaireur"
              />
            </Box>
            <Heading as="h4" size="md" my={6} textAlign="center">
              Étudiant Éclaireur
            </Heading>
            <Text mb={0}>
              Ils étaient à ta place il y a peu. Ils sont forcément sympas
              puisqu’ils se sont inscrits bénévolement pour partager avec toi
              leur expérience ! N'hésite pas à leur poser tes questions.
            </Text>
            <Box
              textAlign="center"
              fontWeight="bold"
              fontSize="lg"
              mt={5}
              mb={5}
            >
              <LinkButton
                variant="outline"
                colorScheme="black"
                {...roundButtonProps}
                href={LANDING_PAGE_ETUDIANT}
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_LANDING_PAGES,
                    GTM_ACTION_LANDING_PAGE_STUDENT,
                    'En savoir plus',
                    undefined,
                    'CTA LP'
                  );
                }}
              >
                En savoir plus
              </LinkButton>
            </Box>
          </Box>
        </SimpleGrid>
        <Heading textAlign="center" as="h4" size="lg" mb={5} mt={8}>
          Prêt.e à rejoindre INSPIRE ?
        </Heading>
        <Center>
          <LinkButton
            size="lg"
            colorScheme="black"
            href={INSCRIPTION_PATH}
            {...roundButtonProps}
            {...emphasisButtonProps}
            onClick={() => {
              pushEvent(
                GTM_CATEGORY_LANDING_PAGES,
                GTM_ACTION_LANDING_PAGE_PRINCIPALE,
                "Je m'inscris",
                undefined,
                'Inscription'
              );
            }}
          >
            Je m'inscris <Icon ml={2} as={RightArrowIcon} />
          </LinkButton>
        </Center>
      </Container>
    </Row>

    {/* Orientation */}
    <Row py={16}>
      <Container maxW="container.lg">
        <Heading textAlign="center" mt={0}>
          Où en es-tu dans ta recherche <NoWrap>d’orientation ?</NoWrap>
        </Heading>
        <Center>
          <Stack
            direction={['column', 'row']}
            borderWidth={[0, 1]}
            borderStyle="solid"
            borderColor={lightBeigeBg}
            borderRadius="24px"
            spacing={3}
            p={1.5}
            textAlign="center"
          >
            <LinkButton
              href="#questionnaire"
              {...roundButtonProps}
              colorScheme="gray"
              color="#333"
              _hover={{ background: 'yellow.500' }}
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_LANDING_PAGES,
                  GTM_ACTION_CLICK_TEXT,
                  'Je suis perdu'
                );
              }}
            >
              Je suis perdu
            </LinkButton>
            <LinkButton
              href="#messagerie"
              {...roundButtonProps}
              colorScheme="gray"
              color="#333"
              _hover={{ background: 'yellow.500' }}
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_LANDING_PAGES,
                  GTM_ACTION_CLICK_TEXT,
                  'J’ai des questions'
                );
              }}
            >
              J’ai des questions
            </LinkButton>
            <LinkButton
              href="#kit"
              {...roundButtonProps}
              colorScheme="gray"
              color="#333"
              _hover={{ background: 'yellow.500' }}
              onClick={() => {
                pushEvent(
                  GTM_CATEGORY_LANDING_PAGES,
                  GTM_ACTION_CLICK_TEXT,
                  'Je me renseigne'
                );
              }}
            >
              Je me renseigne
            </LinkButton>
          </Stack>
        </Center>
      </Container>
    </Row>

    <Row
      pos="relative"
      id="questionnaire"
      scrollMarginTop={16}
      bgColor={lightBeigeBg}
      w={['100%', null, '90%']}
      mx={[0, null, 10]}
      borderRadius={[0, null, '20px']}
      mb={[0, null, 8]}
      py={10}
    >
      <Image
        w="18%"
        src="/img/landing-pages/big-arrow.png"
        pos="absolute"
        right="-8%"
        alt="arrow"
        display={{ base: 'none', md: 'block' }}
      />
      <Container maxW="800px">
        <Stack
          direction={['column', null, 'row-reverse']}
          borderRadius="20px"
          align="center"
          spacing={[6, null, 2]}
        >
          <Center w={{ md: '50%' }}>
            <Image
              maxW="250px"
              src="/img/landing-pages/questionnaire.png"
              alt="phone"
            />
          </Center>
          <Box w={{ md: '50%' }}>
            <Text mt={0}>QUESTIONNAIRE DE 10 MIN</Text>
            <Heading as="h4" fontSize="2xl">
              Quelles études peuvent me{' '}
              <Text as="span" bgColor={inspireYellow} whiteSpace="nowrap">
                convenir ?
              </Text>
            </Heading>
            <Text>
              Réponds à notre questionnaire et découvre les pistes d’études qui
              te conviennent.
            </Text>
            <Center mt={6}>
              <LinkButton
                href={INSCRIPTION_PATH}
                {...roundButtonProps}
                {...emphasisButtonProps}
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_LANDING_PAGES,
                    GTM_ACTION_LANDING_PAGE_PRINCIPALE,
                    'Trouver mes pistes d’étude'
                  );
                }}
              >
                Trouver mes pistes d’étude <Icon ml={2} as={RightArrowIcon} />
              </LinkButton>
            </Center>
          </Box>
        </Stack>
      </Container>
    </Row>

    <Row
      id="messagerie"
      pos="relative"
      scrollMarginTop={16}
      bgColor={lightYellowBg}
      right={[0, null, '-1.5%', '-5%']}
      w={['100%', null, '90%']}
      mx={[0, null, 10]}
      borderRadius={[0, null, '20px']}
      mb={[0, null, 8]}
      py={10}
    >
      <Container
        // The 10 extra px are for the exclamation mark not to go to a newline
        maxW="810px"
      >
        <Stack
          direction={['column', null, 'row']}
          borderRadius="20px"
          align="center"
          spacing={[6, null, 2]}
        >
          <Center w={{ md: '50%' }}>
            <Image
              maxW="300px"
              w="100%"
              src="/img/landing-pages/tchat.png"
              alt="Messagerie"
            />
          </Center>

          <Box w={{ md: '50%' }}>
            <Text mt={0}>MESSAGERIE</Text>
            <Heading as="h4" fontSize="2xl">
              Échange avec des{' '}
              <Text as="span" bgColor={inspireYellow}>
                étudiants
              </Text>{' '}
              pour t’éclairer
            </Heading>
            <Text>
              Qui de mieux qu’un étudiant pour répondre à tes questions ?
              Ambiance, contenu des cours, admission... ils te disent tout !
            </Text>
            <Center mt={6}>
              <LinkButton
                href={INSCRIPTION_PATH}
                {...roundButtonProps}
                {...emphasisButtonProps}
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_LANDING_PAGES,
                    GTM_ACTION_LANDING_PAGE_PRINCIPALE,
                    'Échanger avec un Éclaireur'
                  );
                }}
              >
                Échanger avec un Éclaireur <Icon ml={2} as={RightArrowIcon} />
              </LinkButton>
            </Center>
          </Box>
        </Stack>
      </Container>
    </Row>

    <Row
      id="kit"
      pos="relative"
      scrollMarginTop={16}
      bgColor={lightBlueBg}
      w={['100%', null, '90%']}
      mx={[0, null, 10]}
      borderRadius={[0, null, '20px']}
      py={10}
    >
      <Image
        w="6%"
        pos="absolute"
        src="/img/landing-pages/big-arrow02.png"
        alt="arrow"
        left="-3rem"
        display={{ base: 'none', md: 'block' }}
      />
      <Container maxW="800px">
        <Stack
          direction={['column', null, 'row-reverse']}
          borderRadius="20px"
          align="center"
          spacing={[6, null, 2]}
        >
          <Center w={{ md: '50%' }}>
            <Image maxW="200px" src="/img/landing-pages/kit.png" alt="kit" />
          </Center>

          <Box w={{ md: '50%' }}>
            <Text mt={0}>KIT DE SURVIE</Text>
            <Heading as="h4" fontSize="2xl">
              Logement, coût de la vie... tout ce que tu{' '}
              <Text as="span" bgColor={inspireYellow}>
                dois savoir
              </Text>{' '}
              se trouve <NoWrap>ici !</NoWrap>
            </Heading>
            <Text>
              Découvre notre kit de survie, l'essentiel à connaître pour bien
              démarrer sa vie étudiante !
            </Text>
            <Center mt={6}>
              <LinkButton
                href={KIT_DE_SURVIE_PATH}
                {...roundButtonProps}
                {...emphasisButtonProps}
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_LANDING_PAGES,
                    GTM_ACTION_LANDING_PAGE_PRINCIPALE,
                    'Découvrir le kit de survie'
                  );
                }}
              >
                Découvrir le kit de survie <Icon ml={2} as={RightArrowIcon} />
              </LinkButton>
            </Center>
          </Box>
        </Stack>
      </Container>
    </Row>

    {/* Testimonials */}
    <Row pt={8} pb={16}>
      <Container maxW="container.lg">
        <TestimonialsSlider testimonials={testimonials} />
        <Stack
          mt={12}
          direction={['column', null, 'row']}
          mx="auto"
          maxW="900px"
          spacing={[5, null, 10]}
        >
          <Box>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center">
              Équipe pédagogique
            </Text>
            <Text mb={8}>
              Professeur principal, CPE, proviseur, Psy-EN... Vous participez à
              l’orientation des élèves de votre lycée et vous souhaitez utiliser
              les services d’INSPIRE pour compléter vos actions ?
            </Text>
            <Box textAlign="center">
              <LinkButton
                href={LANDING_PAGE_EQUIPE_PEDAGOGIQUE}
                colorScheme="black"
                variant="outline"
                {...roundButtonProps}
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_LANDING_PAGES,
                    GTM_ACTION_LANDING_PAGE_PROF,
                    'Équipe pédagogique'
                  );
                }}
              >
                Équipe pédagogique <Icon ml={2} as={RightArrowIcon} />
              </LinkButton>
            </Box>
          </Box>
          <Box>
            <Text fontSize="2xl" fontWeight="bold" textAlign="center">
              Étudiant
            </Text>
            <Text mb={8}>
              Tu es étudiant dans le supérieur et tu souhaites partager ton
              expérience avec des lycéens sur ta formation et la vie étudiante ?
            </Text>
            <Box textAlign="center">
              <LinkButton
                href={LANDING_PAGE_ETUDIANT}
                {...roundButtonProps}
                colorScheme="black"
                variant="outline"
                onClick={() => {
                  pushEvent(
                    GTM_CATEGORY_LANDING_PAGES,
                    GTM_ACTION_LANDING_PAGE_STUDENT,
                    'Étudiant éclaireur'
                  );
                }}
              >
                Étudiant éclaireur <Icon ml={2} as={RightArrowIcon} />
              </LinkButton>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Row>

    <Row bg={inspireYellow} py={10}>
      <Container maxW="container.lg">
        <SocialChannels />
      </Container>
    </Row>

    <Row pt={12} pb={6}>
      <Container maxW="container.lg">
        {/* <Heading
          as="h3"
          mt={0}
          mb={0}
          fontFamily={sansFonts}
          textAlign="center"
        >
          Les humains derrière INSPIRE
        </Heading>
        <TeamSlider /> */}
        <Heading as="h3" mt={0} mb={0} textAlign="center">
          Nos partenaires
        </Heading>
        <Text textAlign="center" fontFamily={sansFonts}>
          Merci à nos partenaires pour leur soutien, leur engagement et leur
          confiance dans INSPIRE.
        </Text>
        <PartnersSlider />
      </Container>
    </Row>
  </Box>
);

IndexPage.getWrappers = (page) => (
  <OnlyLoggedOut>
    <Layout variant="simple" withMainRow={false}>
      {page}
    </Layout>
  </OnlyLoggedOut>
);

export default IndexPage;
